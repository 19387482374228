import React from "react";

import styles from "./cardsContainer.module.scss";

import {
  BookmarkBorderOutlined,
  ExtensionOutlined,
  Language,
  LaptopMacOutlined,
  LocalLibraryOutlined,
  QuestionAnswerOutlined,
} from "@mui/icons-material";
import Card from "../Card/Card";

const serviceCardIcons = [
  <LaptopMacOutlined />,
  <LocalLibraryOutlined />,
  <Language />,
  <ExtensionOutlined />,
  <BookmarkBorderOutlined />,
  <QuestionAnswerOutlined />,
];

const serviceCardHeaders = [
  "Smart Study Room in your favorite language",
  "Interactive Learning",
  "FUN LEARNING VIA GAMES AND LIVE INTERACTION",
  "SMART INTERACTIVE iBOOK",
  "ADAPTIVE / PERSONALIZED LEARNING",
  "FOLLOW UPS & ACADEMIC ADVISING",
];

const serviceCardTexts = [
  <>
    If you feel that the lesson is hard to study in English, you can use your
    favorite language. If your eyes are tired to read, listen to the narration.
    <br />
    You will not leave the room until you finish your study, you can take notes,
    learning terminology and new words. More importantly, your class teacher
    will go with you to the Smart Study Room at your own study time.
  </>,
  "You will enjoy studying and getting fun learning by interacting with your lessons and learning contents in several ways.  Not only fun learning but also deeper understanding with less efforts.",
  "You will enjoy studying and getting fun learning by interacting with your lessons and learning contents in several ways. Not only fun learning but also deeper understanding with less efforts.  Now memorizing the terminology and its definition is made fun. Games are automatically generated by the system for you to enjoy memorizing the spelling of the words and remember their definitions.",
  "It is not a PDF book, it is a real interactive book.  Readers can interact with the Smart Objects, the Interactive Objects and the dictionary entries and the iBook-generated games.  Studying and navigation in the book is made easy via the multi-purpose tabs, and many more services.",
  "Educational institutions cannot assign a human tutor to each student.  A teacher cannot act like a private tutor for each student in the class.  A human teacher cannot customize the learning contents to each individual student.  SCube can.",
  <>
    The system will automatically assess your learning achievement and
    satisfaction of lessons&apos;goals. Analytics of your study style will give
    the teacher and theparent a report on your status quo early enough.
  </>,
];

const tos = [
  "/study-room",
  "/interactive-learning",
  "/favorite-language",
  "/fun-learning",
  "/adaptive-learning",
  "/knowledge",
];

const CardsContainer = () => {
  return (
    <div className={styles.wrapper}>
      {serviceCardTexts.map((card, idx) => (
        <div key={idx}>
          <Card
            icon={serviceCardIcons[idx]}
            title={serviceCardHeaders[idx]}
            text={card}
            to={tos[idx]}
          />
        </div>
      ))}
    </div>
  );
};

export default CardsContainer;
